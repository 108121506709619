<template>
  <el-row :gutter="15">
    <el-col :xs="24">
      <div class="jobfeed">
        <el-card class="text-center job">
          <hr>
          <h5 class="px-3 mt-5 mb-2 orange-text bold">This job has been deactivated</h5>
          <p class="px-3 mb-5 small">Please <a href="/freelancer/jobs" class="underline green-text">click here</a> to explore other opportunities.</p>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name : 'deactivated',

  data () {
    return {
      jobs: [],
      errors: {},
      totalResults: 0,
      pmin: '',
      pmax: '',
      perPage: 1
    }
  },

  // directives: {
  //   cleave: {
  //     inserted: (el, binding) => {
  //       el.cleave = new Cleave(el, binding.value || {})
  //     },
  //     update: (el) => {
  //         const event = new Event('input', {bubbles: true});
  //         setTimeout(function () {
  //             el.value = el.cleave.properties.result
  //             el.dispatchEvent(event)
  //         }, 100);
  //     }
  //   }
  // },

  watch: {
    filters: {
      deep: true,
      handler () {
        this.search()
      },
    },
    pmin (v) {
      // const result = v.replace(/\D/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // this.$nextTick(() => this.pmin = result)
      if (v) this.filters.price_min = parseFloat(v.replace(/,/g, ''))
      else this.filters.price_min = 0
    },
    pmax (v) {
      // const result = v.replace(/\D/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // this.$nextTick(() => this.pmax = result)
      if (v) this.filters.price_max = parseFloat(v.replace(/,/g, ''))
      else this.filters.price_max = 0
    }
  },

  created () {
    if(this.$route.query.page) {
      this.filters.page = parseInt(this.$route.query.page)
    }
    this.search()
  },

  methods: {
    pageChange(page) {
      window.history.pushState(null, null, `?page=${page}`)
      this.search()
    },

    // search: _.debounce(function () {
    //   Job.search(this.filters).then(({ data }) => {
    //     this.jobs = data.data
    //     this.totalResults = data.meta.total
    //     this.perPage = data.meta.per_page
    //   }).catch(error => {
    //     if (! error.response) {
    //       return handleError(error)
    //     }

    //     if (error.response.status === 422) {
    //       this.errors = error.response.data.errors
    //     }
    //   })
    // }, 800),
  },
}

</script>
<style lang="scss" scoped>
  .form-control {
    font-size: 14px;
    height: calc(1.5em + .75rem + 5px);
  }
  .form-control:focus, .form-control:active { 
    box-shadow: none;
    outline: none;
    border: 1px solid #E87722 !important;
  }
</style>
<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
      // background-color: #f9f9f9;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;

    }
    .el-pagination {
      text-align: center;
    }
  }
@media (max-width: 767px) {
  .mb-xs-3 {
    margin-bottom: 1rem;
  }
}
</style>
